import React, { useState, useEffect } from "react"; // Add useEffect
import styled from "styled-components";
import axios from "axios"; // Add axios for HTTP requests
import { useAuth } from "../contexts/AuthContext";
// import dummyImg from "../pages/assets/dummy.jpg"; // 이미지 임포트
// import dummyVid from "../pages/assets/ab2cd81153f2128a14b6120f384516d4_w200.webp";

// 스타일 정의
const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;  // This will stack children vertically
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps sections spaced evenly
  width: 100%;  // Use the full width
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const Button = styled.button`
  padding: 10px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.accept ? 'linear-gradient(45deg, #56ab2f, #a8e063)' : 'linear-gradient(45deg, #ff416c, #ff4b2b)'};
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;

const MediaPreview = styled.div`
  width: 100%;
  padding-top: 100%; // Aspect ratio 1:1 (square)
  position: relative;
  margin-bottom: 10px;
`;

const ImagePreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// const VideoPreview = styled.video`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  flex: 0 0 100px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: 30px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333; // Dark gray for better readability
  display: inline-block;
  flex-grow: 1;
`;

const ProfilePhotoVerification = () => {
  const { currentUser } = useAuth();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requests, setRequests] = useState([]);
  const [error, setError] = useState(null);


  //더미 데이터
  requests = {
    message: "학생증 인증 요청 리스트",
    users: [
      {
        "id": "848bdd41-a170-4e4d-a40f-c0d7ba248123",
        "nickName": "닉네임",
        "name": "문유12",
        "univImageDate": "2024-10-06T04:41:44.000Z",
        "univImage": "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSC4DtHTGprsp7K8u0ZlfSDmIDplvQYH5vniT0I3rpcl6wqBh8b",
        "verifyUniv": 1,
        "university": "서울대학교",
        "department": "컴퓨터공학과",
        "profileImage": "https://example.com/profile-image.jpg"
      },
      {
        "id": "848bdd41-a170-4e4d-a40f-c0d7ba248122",
        "nickName": "닉네임1",
        "name": "문유123",
        "univImageDate": "2024-10-06T04:41:44.000Z",
        "univImage": "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSC4DtHTGprsp7K8u0ZlfSDmIDplvQYH5vniT0I3rpcl6wqBh8b",
        "verifyUniv": 1,
        "university": "서울대학교",
        "department": "컴퓨터공학과",
        "profileImage": "https://example.com/profile-image.jpg"
      },
      // 추가 유저 데이터...
    ],
  }
  setRequests(requests.data.users.sort((a, b) => new Date(b.univImageDate) - new Date(a.univImageDate)));

  // useEffect(() => {
  //   const fetchedData = async () => {
  //     if (currentUser) {
  //       try {
  //         const response = await axios.post(
  //           `https://${process.env.REACT_APP_API_DNS}/api/admin/univ-image-list/${currentUser.adminId}`,
  //           {
  //             refreshToken: currentUser.refreshToken,
  //             accessToken: currentUser.accessToken
  //           }
  //         );

  //         if (response.status === 200) {
  //           setRequests(response.data.users.sort((a, b) => new Date(b.univImageDate) - new Date(a.univImageDate)));
  //         } else {
  //           setError("Failed to fetch inquiries");
  //         }
  //       } catch (error) {
  //         setError("Error Detected: " + (error.response ? error.response.data.message : error.message));
  //       }
  //     }
  //   };

  //   fetchedData();
  // }, []);

  // useEffect(() => {
  //   // response로 받은 데이터 (임시로 하드코딩한 예시)
  //   const fetchedData = {
  //     message: "학생증 인증 요청 리스트",
  //     users: [
  //       {
  //         "id": "848bdd41-a170-4e4d-a40f-c0d7ba248123",
  //         "nickName": "닉네임",
  //         "name": "문유12",
  //         "univImageDate": "2024-10-06T04:41:44.000Z",
  //         "univImage": "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSC4DtHTGprsp7K8u0ZlfSDmIDplvQYH5vniT0I3rpcl6wqBh8b",
  //         "verifyUniv": 1,
  //         "university": "서울대학교",
  //         "department": "컴퓨터공학과",
  //         "profileImage": "https://example.com/profile-image.jpg"
  //       },
  //       {
  //         "id": "848bdd41-a170-4e4d-a40f-c0d7ba248122",
  //         "nickName": "닉네임1",
  //         "name": "문유123",
  //         "univImageDate": "2024-10-06T04:41:44.000Z",
  //         "univImage": "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSC4DtHTGprsp7K8u0ZlfSDmIDplvQYH5vniT0I3rpcl6wqBh8b",
  //         "verifyUniv": 1,
  //         "university": "서울대학교",
  //         "department": "컴퓨터공학과",
  //         "profileImage": "https://example.com/profile-image.jpg"
  //       },
  //       // 추가 유저 데이터...
  //     ],
  //   };

  //   // fetchedData.users를 상태에 저장
  //   setRequests(fetchedData.users);
  // }, []);

  // 요청 항목 선택 핸들러
  const handleRequestClick = (request) => {
    setSelectedRequest(request);
  };

  const handleAnswerAccept = async () => {
    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_DNS}/api/admin/univ-image-accept/${currentUser.adminId}?id=${selectedRequest.id}`,
        {},
        {
          headers: {
            'x-access-token': currentUser.accessToken
          }
        }
      );

      if (response.status === 200) {
        alert("승인 처리 완료");

        // 테이블의 verifyUniv 값 업데이트
        setRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === selectedRequest.id
              ? { ...request, verifyUniv: 2 } // verifyUniv 값을 2로 변경
              : request
          )
        );
      } else {
        setError("승인 처리 과정 중 오류 발생");
      }
    } catch (error) {
      console.error("승인 처리 중 오류 발생:", error);
      setError("승인 처리 중 오류가 발생했습니다.");
    }
  };

  const handleAnswerDeny = async () => {
    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_DNS}/api/admin/univ-image-deny/${currentUser.adminId}?id=${selectedRequest.id}`,
        {},
        {
          headers: {
            'x-access-token': currentUser.accessToken
          }
        }
      );

      if (response.status === 200) {
        alert("반려 처리 완료");

        // 테이블의 verifyUniv 값 업데이트
        setRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === selectedRequest.id
              ? { ...request, verifyUniv: 3 } // verifyUniv 값을 3로 변경
              : request
          )
        );
      } else {
        setError("반려 처리 과정 중 오류 발생");
      }
    } catch (error) {
      console.error("반려 처리 중 오류 발생:", error);
      setError("반려 처리 중 오류가 발생했습니다.");
    }
  };



  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>인증 요청 목록</Title>
          <Divider />
          <Table>
            <thead>
              <tr>
                <th>유저아이디</th>
                <th>일자</th>
                <th>유저 닉네임</th>
                <th>유저 이름</th>
                <th>인증 여부</th>
              </tr>
            </thead>
            <tbody>
              {requests.map(request => (
                <tr key={request.id} onClick={() => handleRequestClick(request)}>
                  <td>{request.univImageDate}</td>
                  <td>{request.nickName}</td>
                  <td>{request.name}</td>
                  <td>{request.verifyUniv === 2
                    ? "승인"
                    : request.verifyUniv === 3
                      ? "반려"
                      : "미인증"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* 페이지네이션 등 추가 필요 시 여기에 작성 */}
        </Section>

        <Section width="60%" id="detailSection">
          <Title>프로필 사진 인증하기</Title>
          <Divider />
          {selectedRequest ? (
            <>
              <DetailRow>
                <Label1>요청 일시 </Label1>
                <Value>{selectedRequest.univImageDate.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' })}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>유저 </Label1>
                <Value>{selectedRequest.name}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>학교 </Label1>
                <Value>{selectedRequest.university}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>학과 </Label1>
                <Value>{selectedRequest.department}</Value>
              </DetailRow>
              <DetailRow>
                <div style={{ flex: 1, marginTop: '15px' }}>
                  <Label1>인증 요청 사진 </Label1>
                  <MediaPreview>
                    <ImagePreview src={selectedRequest.univImage} alt="인증 요청 사진" /> {/* 임포트한 이미지 사용 */}
                  </MediaPreview>
                </div>
                <div style={{ flex: 1, marginTop: '15px' }}>
                  <Label1>프로필 이미지</Label1>
                  <MediaPreview>
                    {/* <VideoPreview controls>
                      <source src={dummyVid} type="video/mp4" />
                    </VideoPreview> */}
                    <ImagePreview src={selectedRequest.profileImage} alt="인증 요청 사진" />
                  </MediaPreview>
                </div>
              </DetailRow>
              <ButtonGroup>
                <Button accept onClick={handleAnswerAccept}>승인</Button>
                <Button onClick={handleAnswerDeny}>거절</Button>
              </ButtonGroup>
            </>
          ) : (
            <p>인증 요청 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

export default ProfilePhotoVerification;
